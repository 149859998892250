<template>
    <div class="category-card">
        <h3>{{ t(`resources.categories.${category}`) }}</h3>
        <p class="subtitle">{{ t(`resources.categories.${category}.subtitle`) }}</p>
        <NuxtLink v-slot="{href, navigate}" :to="`/resources/search?category=${category}`" custom>
            <VJoyButton variant="secondary" size="xsmall" :href="href" icon="arrow-right" class="button" @click="navigate" />
        </NuxtLink>
    </div>
</template>

<script lang="ts" setup>
    import {useTranslation} from '#imports';
    import {VJoyButton} from '@maltjoy/core-vue';

    defineProps<{category: string}>();
    const {t} = useTranslation();
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .category-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h3 {
            font-size: 40px;
            line-height: 97%;
            font-weight: 800;
            margin-bottom: var(--joy-core-spacing-2);
            @media (max-width: $tablet-breakpoint) {
                font-size: 30px;
                margin-bottom: var(--joy-core-spacing-4);
            }
        }
        .subtitle {
            margin-bottom: var(--joy-core-spacing-9);
            color: var(--joy-color-neutral-40);
            @media (max-width: $tablet-breakpoint) {
                margin-bottom: var(--joy-core-spacing-5);
            }
        }

        .button {
            --button-color-secondary: var(--joy-color-primary-50);
            --button-bg-color-secondary-hover: var(--joy-color-primary-10);
            padding: 0px 8px;
            joy-icon {
                margin-right: 0px !important;
            }
        }
    }
</style>
